<template>
  <div class="home">
    <itc-carousel></itc-carousel>
    <div class="container mt-4">
      <h1 class="d-none d-md-block">Productos destacados</h1>
      <h3 class="d-block d-md-none">Productos destacados</h3>
    </div>
    <div class="container h-100 pb-4 my-4 gray-background">
      <div class="row">
        <itc-product  v-for="(featured, i) in featuredItems" :key="i" :product="featured"></itc-product>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Carousel from '../../components/shop/Carousel'
import Product from '../../components/shop/Product'

export default {
  data(){
    return {
      featuredItems: []
    }
  },
  components: {
    'itc-carousel': Carousel,
    'itc-product': Product
  },
  async mounted(){
    const resp = await axios.get('/product/featured?searchInStock=true')
    this.featuredItems = resp.data
  }
}
</script>

<style lang="scss" scoped>
  .gray-background{
    background-color:#EFEFEF
  }
</style>
