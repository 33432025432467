<template>
    <div class="carousel slide" data-ride='carousel' id="multi_item">
        <div class="carousel-inner">
            <div v-for="(path, i) in 3" :key="i" class="carousel-item" :class="{active: (i == 0 || (i + 3) == 3)? true: false}">
                <img :src="`${publicPath}${carouselPaths[i]}`" class="d-none d-md-block w-100 h-100">
                <img :src="`${publicPath}${carouselPaths[i + 3]}`" class="d-block d-md-none w-100 h-100">
            </div>
        </div>
        <a class="carousel-control-prev" href="#multi_item" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#multi_item" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return {
            carouselPaths: [],
            publicPath: process.env.VUE_APP_PUBLIC_PATH
        }
    },
    async created(){
        const resp = await axios.get('/carousel-image')
        this.carouselPaths = resp.data
    },
}
</script>

<style lang="scss" scoped>
.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
}
</style>